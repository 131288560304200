import type { ValidationRuleWithoutParams } from "@vuelidate/core";
import {
	required,
	email,
	sameAs as sameAsOrigin,
	minLength as minLengthOrigin,
	maxLength as maxLengthOrigin,
	numeric,
	requiredIf as requiredIfOrigin
} from "@vuelidate/validators";

required.$message = "Dit veld is verplicht";
email.$message = "Dit is geen geldig e-mailadres";
const sameAs = (val: any) => {
	const validator = sameAsOrigin(val);
	validator.$message = () => "De velden komen niet overeen";

	return validator;
};
const minLength = (val: any) => {
	const validator = minLengthOrigin(val);
	validator.$message = () => `Dit veld moet minimaal ${val} tekens bevatten`;

	return validator;
};
const maxLength = (val: any) => {
	const validator = maxLengthOrigin(val);
	validator.$message = () => `Dit veld mag maximaal ${val} tekens bevatten`;

	return validator;
};
numeric.$message = "Dit veld mag alleen cijfers bevatten";

const requiredIf = (val: any) => {
	const validator = requiredIfOrigin(val);
	validator.$message = () => "Dit veld is verplicht";

	return validator;
};

const postcode = (pattern: Ref<RegExp> | RegExp) => {
	return {
		$message: "Dit is geen geldige postcode",
		$validator: (value: string) => {
			return value.match(unref(pattern)) !== null;
		}
	};
};

const onlyIf = (condition: Ref<boolean>, ...validators: ValidationRuleWithoutParams[]) => {
	const randomKey = (Math.random() + 1).toString(36).substring(7);
	return validators.reduce((acc, validator, index) => {
		return {
			...acc,
			[`$onlyIf_${randomKey}_${index}`]: {
				...validator,
				$validator: (value: any, siblingState: any, vm: any) => {
					return unref(condition) ? validator.$validator(value, siblingState, vm) : true;
				}
			}
		};
	}, {});
};

const firstOrLastName = {
	$message: "Dit is geen geldige naam",
	$validator: (value: string) => {
		const regex = /^[A-Za-zÀ-ÖØ-öø-ÿ' -]+$/;
		return regex.test(value);
	}
};

const businessName = {
	$message: "Dit is geen geldige bedrijfsnaam",
	$validator: (value: string) => {
		const regex = /^[A-Za-z0-9À-ÖØ-öø-ÿ'&., -+@!*#/()]+$/;
		return regex.test(value);
	}
};

const phoneNumber = {
	$message: "Dit is geen geldig telefoonnummer",
	$validator: (value: string) => {
		return value.match(/^\+?[0-9]{1,4}?[0-9 \-()]{7,16}$/) !== null;
	}
};

const url = {
	$message: "Dit is geen geldige URL",
	$validator: (value: string) => {
		return value.match(/^(https?:\/\/)?([\w\d\-_]+\.)?[\w\d\-_]+\.\w{2,}(\/.*)?$/) !== null;
	}
};

export {
	required,
	email,
	sameAs,
	minLength,
	numeric,
	requiredIf,
	postcode,
	phoneNumber,
	maxLength,
	firstOrLastName,
	businessName,
	onlyIf,
	url
};
